import { Injectable } from '@angular/core';
import { CodeList, ICodeListTextDeps } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { Design, IDesignStateBase } from '@profis-engineering/pe-ui-common/entities/design';
import { IDropdownProps } from '@profis-engineering/pe-ui-common/entities/main-menu/dropdown-props';
import { IImageNameRadioGroupItem } from '@profis-engineering/pe-ui-common/entities/main-menu/image-name-radio-group-props';
import { IMenu, ITab } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import { BaseControl, Button, DropDown, ImageNameSelectionGroup, Menu, TextBox, ToggleButtonGroup, UIPropertyBaseControl } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { IPopupGridPartialProps } from '@profis-engineering/pe-ui-common/entities/main-menu/popup-grid-props';
import { ITextBoxProps } from '@profis-engineering/pe-ui-common/entities/main-menu/textbox-props';
import { IModalGridComponentInput, IModalGridItem } from '@profis-engineering/pe-ui-common/entities/modal-grid';
import { IModalOpened, ModalOptions } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { ICalculationResult } from '@profis-engineering/pe-ui-common/services/calculation.common';
import { IMenuServiceCodeListIdWithItems, IMenuServiceDropdownItemProps, IMenuServiceExtensions, MenuServiceInjected, TabItem } from '@profis-engineering/pe-ui-common/services/menu.common';
import { LocalizationService } from './localization.service';
import { StaticMenuService } from './static-menu.service';
import { IToggleButtonGroupItem } from '@profis-engineering/pe-ui-common/entities/main-menu/toggle-button-group-props';
import { IButtonGroupItem } from '@profis-engineering/pe-ui-common/entities/main-menu/button-group-props';
import { IButtonProps } from '@profis-engineering/pe-ui-common/entities/main-menu/button-props';
import { ICheckboxProps } from '@profis-engineering/pe-ui-common/entities/main-menu/checkbox-props';
import { IToggleButtonProps } from '@profis-engineering/pe-ui-common/entities/main-menu/toggle-button-props';
import { IToggleImageButtonProps } from '@profis-engineering/pe-ui-common/entities/main-menu/toggle-image-button-props';
import { IImageNameSelectionGroupItem } from '@profis-engineering/pe-ui-common/entities/main-menu/image-name-selection-group-props';
import { getSpriteAsIconStyle } from '../sprites';
import { IIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';
import { DataService } from './data.service';
import { PickKeysByValue } from 'ts-essentials/dist/pick-keys-by-value';
import { UserService } from './user.service';
import { DesignService, PropertyIdValue } from './design.service';
import { NumberService } from './number.service';
import { FavoritesService } from './favorites.service';

interface CodeListEntity {
    id: number;
    name?: string;
    nameKey?: string;
    image?: string;
    tooltipTitleKey?: string;
    tooltipKey?: string;
}

export interface CodeLists {
    region: CodeList[];
    unitLength: CodeList[];
    unitStress: CodeList[];
    unitForce: CodeList[];
    unitMoment: CodeList[];

    temperature: CodeList[];
}

class CodeListItem extends CodeList {}

export type CodeListType = keyof CodeLists;

export interface SetMenuOptions {
    propertyChange: (propertyChanges: PropertyIdValue[]) => Promise<void>;
}

@Injectable({
    providedIn: 'root'
})
export class MenuService extends MenuServiceInjected<Design> implements IMenuServiceExtensions {
    public codeLists: CodeLists = {} as CodeLists;

    private propertyChange!: (propertyChanges: PropertyIdValue[]) => Promise<void>;

    constructor(
        private localizationService: LocalizationService,
        private staticMenuService: StaticMenuService,
        private dataService: DataService,
        private userService: UserService,
        private designService: DesignService,
        private numberService: NumberService,
        private favoritesService: FavoritesService
    ) {
        super();
    }

    private get designDetails() {
        return this.userService.design.designDetails;
    }

    public init() {
        this.initCodeLists();
    }

    public setMenu(options: SetMenuOptions) {
        this.propertyChange = options.propertyChange;

        this.menuExtensions.localizationExtension = {
            getTranslation: this.localizationService.getString.bind(this.localizationService),
            checkForTranslation: this.localizationService.hasTranslation.bind(this.localizationService),
        };

        this.menuExtensions.validate = this.validate.bind(this);
        this.menuExtensions.getMenuStructure = this.getMenuStructure.bind(this);
        this.menuExtensions.getMenuCommands = this.getMenuCommands.bind(this);
        this.menuExtensions.getMenuRegionCommands = this.getMenuRegionCommands.bind(this);
        this.menuExtensions.getMenuModals = this.getMenuModals.bind(this);

        this.menuExtensions.calculateAsync = this.calculateAsync.bind(this);
        this.menuExtensions.trackUsage = this.trackUsage.bind(this);

        this.menuExtensions.getDesignCodeList = this.getDesignCodeList.bind(this);
        this.menuExtensions.getProjectCodeList = this.getProjectCodeList.bind(this);
        this.menuExtensions.getCodeListItems = this.getCodeListItems.bind(this);
        this.menuExtensions.getCodeListIdWithItems = this.getCodeListIdWithItems.bind(this);
        this.menuExtensions.getCodeListItemText = this.getCodeListItemText.bind(this);
        this.menuExtensions.getDesignStandard = this.getDesignStandard.bind(this);

        this.menuExtensions.getTabControlId = this.getTabControlId.bind(this);
        this.menuExtensions.updateTabDisplayKey = this.updateTabDisplayKey.bind(this);
        this.menuExtensions.updateTabs = this.updateTabs.bind(this);
        this.menuExtensions.isFavoritesTabHidden = this.isFavoritesTabHidden.bind(this);

        this.menuExtensions.getRegionDisplayId = this.getRegionDisplayId.bind(this);
        this.menuExtensions.getRegionId = this.getRegionId.bind(this);
        this.menuExtensions.getRegionFavoritesId = this.getRegionFavoritesId.bind(this);
        this.menuExtensions.setRegionKbFields = this.setRegionKbFields.bind(this);

        this.menuExtensions.updateControlKBNumberAci = this.updateControlKBNumberAci.bind(this);

        this.menuExtensions.clientHidden = this.clientHidden.bind(this);

        this.menuExtensions.formatDisplayStringModule = this.formatDisplayStringModule.bind(this);
        this.menuExtensions.getFormattedKBLinkByRegionSpecificTemplate = this.getFormattedKBLinkByRegionSpecificTemplate.bind(this);
        this.menuExtensions.getLocalizedStringWithTranslationFormat = this.getLocalizedStringWithTranslationFormat.bind(this);

        this.menuExtensions.createMainMenuControlModule = this.createMainMenuControlModule.bind(this);

        this.menuExtensions.overrideTextBoxProps = this.overrideTextBoxProps.bind(this);
        this.menuExtensions.overrideTextBoxUnitGroup = this.overrideTextBoxUnitGroup.bind(this);
        this.menuExtensions.setTextBoxOnStateChanged = this.setTextBoxOnStateChanged.bind(this);

        this.menuExtensions.overrideDropdownProps = this.overrideDropdownProps.bind(this);
        this.menuExtensions.getDropdownItemProps = this.getDropdownItemProps.bind(this);
        this.menuExtensions.setDropdownOnAllowedValuesChanged = this.setDropdownOnAllowedValuesChanged.bind(this);
        this.menuExtensions.setDropdownOnStateChanged = this.setDropdownOnStateChanged.bind(this);

        this.menuExtensions.overrideCheckboxProps = this.overrideCheckboxProps.bind(this);

        this.menuExtensions.overrideCheckboxGroupProps = this.overrideCheckboxGroupProps.bind(this);

        this.menuExtensions.overrideRadioButtonProps = this.overrideRadioButtonProps.bind(this);

        this.menuExtensions.overrideRadioButtonGroupProps = this.overrideRadioButtonGroupProps.bind(this);
        this.menuExtensions.applyProperSingleImage = this.applyProperSingleImage.bind(this);
        this.menuExtensions.getApplySortOrderItemIds = this.getApplySortOrderItemIds.bind(this);
        this.menuExtensions.getRadioGroupParentId = this.getRadioGroupParentId.bind(this);

        this.menuExtensions.overrideButtonProps = this.overrideButtonProps.bind(this);
        this.menuExtensions.getButtonDisplayText = this.getButtonDisplayText.bind(this);

        this.menuExtensions.overrideButtonGroupProps = this.overrideButtonGroupProps.bind(this);
        this.menuExtensions.updateButtonGroupItemProps = this.updateButtonGroupItemProps.bind(this);

        this.menuExtensions.overrideToggleButtonProps = this.overrideToggleButtonProps.bind(this);

        this.menuExtensions.overrideToggleImageButtonProps = this.overrideToggleImageButtonProps.bind(this);

        this.menuExtensions.overrideToggleButtonGroupProps = this.overrideToggleButtonGroupProps.bind(this);
        this.menuExtensions.updateToggleButtonGroupItemCodeListProps = this.updateToggleButtonGroupItemCodeListProps.bind(this);
        this.menuExtensions.updateToggleButtonGroupItemProps = this.updateToggleButtonGroupItemProps.bind(this);
        this.menuExtensions.getToggleButtonGroupAllowedValues = this.getToggleButtonGroupAllowedValues.bind(this);

        this.menuExtensions.overrideGroupProps = this.overrideGroupProps.bind(this);
        this.menuExtensions.setGroupChildProps = this.setGroupChildProps.bind(this);

        this.menuExtensions.overrideLabelProps = this.overrideLabelProps.bind(this);

        this.menuExtensions.overrideRotateProps = this.overrideRotateProps.bind(this);

        this.menuExtensions.overridePopupGridProps = this.overridePopupGridProps.bind(this);
        this.menuExtensions.customizePopupGridModal = this.customizePopupGridModal.bind(this);
        this.menuExtensions.customizePopupGridItems = this.customizePopupGridItems.bind(this);
        this.menuExtensions.getPopupGridHideShowDescriptionOnButton = this.getPopupGridHideShowDescriptionOnButton.bind(this);

        this.menuExtensions.overridePopupGridPartialProps = this.overridePopupGridPartialProps.bind(this);
        this.menuExtensions.customizePopupGridPartialControl = this.customizePopupGridPartialControl.bind(this);
        this.menuExtensions.customizePopupGridPartialItems = this.customizePopupGridPartialItems.bind(this);
        this.menuExtensions.customizePopupGridPartialModal = this.customizePopupGridPartialModal.bind(this);

        this.menuExtensions.overrideDlubalImportExportProps = this.overrideDlubalImportExportProps.bind(this);
        this.menuExtensions.overrideSAP2000ImportExportProps = this.overrideSAP2000ImportExportProps.bind(this);
        this.menuExtensions.overrideRobotImportExportProps = this.overrideRobotImportExportProps.bind(this);
        this.menuExtensions.overrideETABSImportExportProps = this.overrideETABSImportExportProps.bind(this);
        this.menuExtensions.overrideStaadProImportExportProps = this.overrideStaadProImportExportProps.bind(this);

        this.menuExtensions.overrideImageNameRadioGroupProps = this.overrideImageNameRadioGroupProps.bind(this);
        this.menuExtensions.updateImageNameRadioGroupItemProps = this.updateImageNameRadioGroupItemProps.bind(this);
        this.menuExtensions.updateImageNameRadioGroupSortOrder = this.updateImageNameRadioGroupSortOrder.bind(this);

        this.menuExtensions.overrideImageNameSelectionGroupProps = this.overrideImageNameSelectionGroupProps.bind(this);
        this.menuExtensions.updateImageNameSelectionGroupItemProps = this.updateImageNameSelectionGroupItemProps.bind(this);

        this.menuExtensions.overrideSwitchWithDescriptionProps = this.overrideSwitchWithDescriptionProps.bind(this);

        this.menuExtensions.overrideRangeSliderProps = this.overrideRangeSliderProps.bind(this);

        this.menuExtensions.overrideTabGroupProps = this.overrideTabGroupProps.bind(this);
        this.menuExtensions.overrideTabGroupItems = this.overrideTabGroupItems.bind(this);
        this.menuExtensions.getTabGroupItemsByTag = this.getTabGroupItemsByTag.bind(this);
        this.menuExtensions.setFooterControlVisibility = this.setFooterControlVisibility.bind(this);
    }

    private initCodeLists() {
        this.initUnits();

        this.codeLists.region = this.toCodeList(this.dataService.appData, 'regions');
        this.codeLists.temperature = this.toCodeList(this.dataService.appData, 'temperatures');
    }

    private initUnits() {
        this.codeLists.unitLength = this.toCodeList(this.dataService.units, 'length');
        this.codeLists.unitStress = this.toCodeList(this.dataService.units, 'stress');
        this.codeLists.unitForce = this.toCodeList(this.dataService.units, 'force');
        this.codeLists.unitMoment = this.toCodeList(this.dataService.units, 'moment');
    }

    private toCodeList<T>(obj: T, property: PickKeysByValue<T, CodeListEntity[]>): CodeList[] {
        const values = obj?.[property] as CodeListEntity[] | undefined;

        if (values == null) {
            console.warn(`missing code list ${property as string}`);
            return [];
        }

        return values.map(x => new CodeListItem({
            id: x.id,
            name: x.name,
            nameResourceKey: x.nameKey,
            image: x.image,
            tooltipDisplayKey: x.tooltipKey,
            tooltipTitleDisplayKey: x.tooltipTitleKey
        }));
    }

    // Data
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public validate(design: Design) {
        // TODO FILIP: do we need this?
    }

    public getMenuStructure(): Menu<BaseControl<string>, string> | undefined {
        return this.staticMenuService.getMenu() as Menu<BaseControl<string>, string>;
    }

    public getMenuCommands(): Record<string, (navigationControl?: BaseControl<string> | undefined) => void> {
        return {};
    }

    public getMenuRegionCommands(): Record<string, () => void> {
        // TODO FILIP: fix
        return {};
        // return {
        //     ['OpenCastInSplicedReinforcementPopup']: () => this.modalService.openInfoDialogC2C('OpenCastInSplicedReinforcementPopup'),
        //     ['OpenAnchorTheoryDataPopup']: () => this.modalService.openInfoDialogC2C('OpenAnchorTheoryDataPopup'),
        //     ['OpenCrackWidthPopup']: () => this.modalService.openInfoDialogC2C('OpenCrackWidthPopup',  'lg')
        // };
    }

    public getMenuModals(): Record<number, (input?: object | undefined) => IModalOpened> {
        return {};
    }

    // Common
    public async calculateAsync(design: Design, changeFn?: ((design: Design) => void) | undefined): Promise<ICalculationResult> {
        changeFn?.(design);

        // get changes
        design.modelChanges.observe();
        const changes = design.modelChanges.changes;
        design.modelChanges.clear();

        // call update if we have changes
        if (changes.length > 0) {
            const propertyChanges = changes.map((x): PropertyIdValue => ({
                propertyId: x.name as any,
                propertyValue: x.newValue as any
            }));
            await this.propertyChange(propertyChanges);
        }

        return undefined as unknown as ICalculationResult;
    }

    public trackUsage() {
        /* Nothing to do. */
    }


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public getDesignCodeList(codelistName: string): number {
        // TODO FILIP: why does menu need a number id for a codelist?
        return 0;
        // return DesignCodeList[codelistName as keyof typeof DesignCodeList];
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public getProjectCodeList(codelistName: string): number {
        // TODO FILIP: why does menu need a number id for a codelist?
        return 0;
        // return ProjectCodeList[codelistName as keyof typeof ProjectCodeList];
    }

    public getCodeListItems(navigationControl: UIPropertyBaseControl<string>, design: Design, codeList: number): CodeList[] | undefined {

        if (codeList != null) {
            return this.codeLists[codeList as unknown as CodeListType];
        }

        this.logMissingCodeList(navigationControl, design);
        return undefined;
    }

    public getCodeListIdWithItems(design: Design, navigationControl: UIPropertyBaseControl<string>): IMenuServiceCodeListIdWithItems | undefined {
        return {
            codeList: navigationControl.CodelistName as unknown as number,
            codeListItems: this.codeLists[navigationControl.CodelistName as CodeListType]
        };
    }

    public getCodeListItemText(design: Design, codeList: number, codeListItem: CodeList, codeListDeps: ICodeListTextDeps): string | undefined {
        return codeListItem.getTranslatedNameText(codeListDeps);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public getDesignStandard(design: Design): CodeList | undefined {
        // TODO FILIP: why does the menu need design standard?
        return undefined;
        // return this.codeListService.projectCodeListsC2C[ProjectCodeList.DesignStandardsC2C].find(d => d.id == design.designStandardC2C?.id);
    }


    public getTabControlId(name: string): string | undefined {
        return name;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public updateTabDisplayKey(tab: ITab): void {
        // TODO FILIP: fix
        // if (tab.controlId == 'existingreinforcement-tab') {
        //     tab.displayKey = this.userService.design.connectionType == ConnectionType.StructuralJoints && this.userService.design.designStandardC2C?.id == DesignStandard.ETAG ?
        //         'Agito.Hilti.C2C.Navigation.Reinforcement.ConcreteReinforcement' : 'Agito.Hilti.C2C.Navigation.ExistingReinforcement';
        // }
    }

    public updateTabs() {
        /* Nothing to do. */
    }

    public isFavoritesTabHidden() {
        return false;
    }

    isTabDisabled(): boolean {
        return false;
    }

    public getRegionDisplayId(tabName: string, regionName: string): string | undefined {
        return `${tabName}-${regionName}`;
    }

    public getRegionId(tabName: string, regionName: string): string | undefined {
        return `${tabName}-${regionName}`;
    }

    public getRegionFavoritesId(id: string): string | undefined {
        return this.favoritesService.getMenuRegionIdFavorites(id, this.designDetails.designTypeId);
    }

    public setRegionKbFields() {
        /* Nothing to do. */
    }


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public updateControlKBNumberAci(design: Design, navigationControl: UIPropertyBaseControl<string>): void {
        // TODO FILIP: fix
        // const kbNumberRegions = design.designData.designCodeListsC2C[DesignCodeList.KBNumberControlRegion]?.find(x => x.id == navigationControl.UIPropertyId)?.kbNumberRegion;
        // if (kbNumberRegions) {
        //     navigationControl.KBNumberAciRegion = kbNumberRegions;
        // }
    }


    public clientHidden() {
        return false;
    }


    public formatDisplayStringModule(textKey: string, design: Design, codeList?: number | undefined, uiPropertyId?: number | undefined): string | undefined {
        if (codeList) {
            return this.formatStringByCodeListC2C(textKey, design, codeList);
        }

        if (uiPropertyId) {
            return this.formatStringByUiPropertyId(textKey, design, uiPropertyId);
        }

        return this.localizationService.getString(textKey);
    }


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public getFormattedKBLinkByRegionSpecificTemplate(design: Design, kbNumberRegion: { [key: string]: string }): string | undefined {
        // TODO FILIP: fix
        // const designStandardId = design.designStandardC2C?.id;

        // if ((designStandardId == DesignStandard.ACI || designStandardId == DesignStandard.CSA) && kbNumberRegion != null) {
        //     const selectedRegion = this.userSettingsService.getCommonRegionById(this.userSettingsService.settings.application.general.regionId.value as number);
        //     const kbNumber = kbNumberRegion[selectedRegion.id] ?? kbNumberRegion[0];
        //     if (kbNumber != null && selectedRegion.profis3KBUrlAci != null) {
        //         return this.createKBLink(selectedRegion.profis3KBUrlAci, kbNumber);
        //     }
        // }

        return undefined;
    }

    public getLocalizedStringWithTranslationFormat() {
        return '';
    }

    // Controls
    public createMainMenuControlModule() {
        return undefined as any;
    }

    // TextBox
    public overrideTextBoxProps() {
        /* Nothing to do. */
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public overrideTextBoxUnitGroup(design: Design, controlProps: ITextBoxProps, navigationControl: TextBox<string>, setState?: (() => void) | undefined): void {
        // TODO FILIP: fix
        // controlProps.beforeValueChanged = () => {
        //     if (navigationControl.UIPropertyId == PropertyMetaDataC2C.Loads_C2C_NormalStressACI.id ||
        //         navigationControl.UIPropertyId == PropertyMetaDataC2C.Loads_C2C_NormalStressCSA.id) {
        //         controlProps.unitGroup = design.designData.projectDesign?.loads.loadingDefinitionType == LoadingDefinitionType.ShearStress
        //             ? UnitGroup.Stress
        //             : UnitGroup.Force;
        //         setState?.();
        //     }
        // };
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public setTextBoxOnStateChanged(state: IDesignStateBase, controlProps: ITextBoxProps, navigationControl: TextBox<string>): void {
        // TODO FILIP: fix
        // if (
        //     navigationControl.UIPropertyId == PropertyMetaDataC2C.Loads_C2C_NormalStressACI.id
        //     ||
        //     navigationControl.UIPropertyId == PropertyMetaDataC2C.Loads_C2C_NormalStressCSA.id
        // ) {
        //     const stateC2C = state as IDesignState;
        //     navigationControl.UnitGroup = stateC2C.projectDesign.loads.loadingDefinitionType == LoadingDefinitionType.ShearStress
        //         ? UnitGroup.Stress
        //         : UnitGroup.Force;
        //     controlProps.unitGroup = navigationControl.UnitGroup;
        // }
    }

    // Dropdown
    public overrideDropdownProps() {
        /* Nothing to do. */
    }

    public getDropdownItemProps(): IMenuServiceDropdownItemProps | undefined {
        return {};
    }

    public setDropdownOnAllowedValuesChanged() {
        /* Nothing to do. */
    }

    public setDropdownOnStateChanged(
        design: Design,
        designCodeList: number,
        state: IDesignStateBase,
        _menu: IMenu,
        navigationControl: DropDown<string>,
        _controlProps: IDropdownProps,
        onStateChangeFn: (formatTextFn: (codeListItem: CodeList, unit?: UnitType | undefined) => string, unit?: UnitType | undefined) => IDropdownProps
    ): IDropdownProps | undefined {
        return onStateChangeFn((codeListItem) => this.getCodeListItemText(design, designCodeList, codeListItem, { localizationService: this.localizationService, numberService: this.numberService })!, undefined);
    }

    // Checkbox
    public overrideCheckboxProps(_design: Design, controlProps: ICheckboxProps) {
        if (controlProps.iconImage != null) {
            controlProps.iconImageStyle = getSpriteAsIconStyle(controlProps.iconImage);
            controlProps.iconImageSelectedStyle = getSpriteAsIconStyle(`${controlProps.iconImage}-selected`);
        }
    }

    // CheckboxGroup
    public overrideCheckboxGroupProps() {
        /* Nothing to do. */
    }

    // RadioButton
    public overrideRadioButtonProps() {
        /* Nothing to do. */
    }

    // RadioButtonGroup
    public overrideRadioButtonGroupProps() {
        /* Nothing to do. */
    }

    public applyProperSingleImage(items: IImageNameRadioGroupItem[], design: Design): void {
        // update image prop for 'single' ApplicationType
        this.applyProperSingleApplicationTypeImage(items, design);
    }

    public getApplySortOrderItemIds(): number[] | undefined {
        return undefined;
    }

    public getRadioGroupParentId(): number | undefined {
        return undefined;
    }

    // Button
    public overrideButtonProps(_design: Design, controlProps: IButtonProps) {
        if (controlProps.image != null) {
            controlProps.imageStyle = getSpriteAsIconStyle(controlProps.image);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public getButtonDisplayText(design: Design, navigationControl: Button<string>): string | undefined {
        // TODO FILIP: fix
        return undefined;
        // const isViewApproval = [PropertyMetaDataC2C.Product_C2C_ViewApproval.id, PropertyMetaDataC2C.Product_C2C_ConnectorViewApproval.id].find(x => x == navigationControl.UIPropertyId);

        // const displayUIProperty = navigationControl.DisplayUIPropertyId != null ? design.model[navigationControl.DisplayUIPropertyId] as any : null;
        // let translationKeySuffix = '';
        // if (isViewApproval) {
        //     translationKeySuffix = isHnaBasedDesignStandard(design.designStandardC2C?.id)
        //         ? '.HNA'
        //         : '.ETA';
        // }

        // const displayText = navigationControl.DisplayKey != null && navigationControl.DisplayKey != '' ? this.localizationService.getString(navigationControl.DisplayKey + translationKeySuffix) : null;
        // return displayUIProperty ?? displayText;
    }

    // ButtonGroup
    public overrideButtonGroupProps() {
        /* Nothing to do. */
    }

    public updateButtonGroupItemProps(_design: Design, _navigationControl: Button, item: IButtonGroupItem) {
        if (item.image != null && item.image != '') {
            item.imageStyle = getSpriteAsIconStyle(item.image);
        }
    }

    // ToggleButton
    public overrideToggleButtonProps(_design: Design, controlProps: IToggleButtonProps) {
        controlProps.imageStyle = this.getIconStyleForImage(controlProps.image);
    }

    // ToggleImageButton
    public overrideToggleImageButtonProps(_design: Design, controlProps: IToggleImageButtonProps) {
        if (controlProps.image != null) {
            controlProps.imageStyle = getSpriteAsIconStyle(controlProps.image);
        }

        if (controlProps.alternateImage != null) {
            controlProps.alternateImageStyle = getSpriteAsIconStyle(controlProps.alternateImage);
        }
    }

    // ToggleButtonGroup
    public overrideToggleButtonGroupProps() {
        /* Nothing to do. */
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public updateToggleButtonGroupItemCodeListProps(design: Design, navigationControl: ToggleButtonGroup<string>, codeListItem: CodeList): void {
       /* Nothing to do. */
    }

    public updateToggleButtonGroupItemProps(__design: Design, _navigationControl: ToggleButtonGroup, item: IToggleButtonGroupItem) {
        item.imageStyle = this.getIconStyleForImage(item.image);
    }

    public getToggleButtonGroupAllowedValues(_codeList: number, allowedValues: number[]): number[] {
        return allowedValues;
    }

    // Group
    public overrideGroupProps() {
        /* Nothing to do. */
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public setGroupChildProps(design: Design, childNavigationControl: UIPropertyBaseControl<string>): void {
        // TODO FILIP: fix
        // const kbNumberRegions = design.designData.designCodeListsC2C[DesignCodeList.KBNumberControlRegion]?.find(x => x.id == childNavigationControl.UIPropertyId)?.kbNumberRegion;
        // if (kbNumberRegions) {
        //     childNavigationControl.KBNumberAciRegion = kbNumberRegions;
        // }
    }

    // Label
    public overrideLabelProps() {
        /* Nothing to do. */
    }

    // Rotate
    public overrideRotateProps() {
        /* Nothing to do. */
    }

    // PopupGrid
    public overridePopupGridProps() {
        /* Nothing to do. */
    }

    public customizePopupGridModal(_modalProps: IModalGridComponentInput<IModalGridItem<number>>, modalOpts: ModalOptions): void {
        modalOpts.size = 'lg';
    }

    public customizePopupGridItems(items: IModalGridItem<number>[]): IModalGridItem<number>[] {
        items?.forEach(item => {
            if (item.image != null && item.image != '') {
                item.imageStyle = {
                    elementStyle: getSpriteAsIconStyle(item.image)
                };
            }
        });

        return items;
    }

    public getPopupGridHideShowDescriptionOnButton() {
        return false;
    }

    // PopupGridPartial
    public overridePopupGridPartialProps() {
        /* Nothing to do. */
    }

    public customizePopupGridPartialControl(controlProps: IPopupGridPartialProps) {
        controlProps.numberOfButtons = 3;
    }

    public customizePopupGridPartialItems(items: IModalGridItem<number>[]): IModalGridItem<number>[] {
        items?.forEach(item => {
            if (item.image != null && item.image != '') {
                item.imageStyle = {
                    elementStyle: getSpriteAsIconStyle(item.image)
                };
            }
        });

        return items;
    }

    public customizePopupGridPartialModal(_modalProps: IModalGridComponentInput<IModalGridItem<number>>, modalOpts: ModalOptions) {
        modalOpts.size = 'lg';
    }

    // Integrations
    public overrideDlubalImportExportProps() {
        /* Nothing to do. */
    }

    public overrideSAP2000ImportExportProps() {
        /* Nothing to do. */
    }

    public overrideRobotImportExportProps() {
        /* Nothing to do. */
    }

    public overrideETABSImportExportProps() {
        /* Nothing to do. */
    }

    public overrideStaadProImportExportProps() {
        /* Nothing to do. */
    }

    // ImageNameRadioGroup
    public overrideImageNameRadioGroupProps() {
        /* Nothing to do. */
    }

    public updateImageNameRadioGroupItemProps(_design: Design, _navigationControl: UIPropertyBaseControl, item: IImageNameRadioGroupItem) {
        item.imageStyle = this.getIconStyleForImage(item.image);
    }

    public updateImageNameRadioGroupSortOrder(): Promise<void> {
        return Promise.resolve();
    }

    // ImageNameSelectionGroup
    public overrideImageNameSelectionGroupProps() {
        /* Nothing to do. */
    }

    public updateImageNameSelectionGroupItemProps(_design: Design, _navigationControl: ImageNameSelectionGroup, item: IImageNameSelectionGroupItem) {
        item.imageStyle = this.getIconStyleForImage(item.image);
    }

    // SwitchWithDescription
    public overrideSwitchWithDescriptionProps() {
        /* Nothing to do. */
    }

    // RangeSlider
    public overrideRangeSliderProps() {
        /* Nothing to do. */
    }

    // TabGroup
    public overrideTabGroupProps() {
        /* Nothing to do. */
    }

    public overrideTabGroupItems() {
        /* Nothing to do. */
    }

    public getTabGroupItemsByTag(childNavigationControls: UIPropertyBaseControl<string>[], tab: TabItem): UIPropertyBaseControl<string>[] {
        return childNavigationControls.filter(x => x.ParentControlTag == tab.Tag);
    }

    public setFooterControlVisibility(design: Design, menu: IMenu): IMenu | undefined {
        return menu;
    }

    private logMissingCodeList(navigationControl: UIPropertyBaseControl, design: Design) {
        const isVisible = navigationControl.UIPropertyId == null || !design.properties.get(navigationControl.UIPropertyId).hidden;

        if (isVisible) {
            console.warn('Missing code list: %s', navigationControl.CodelistName);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private formatStringByCodeListC2C(textKey: string, design: Design, codeList: number) {
        // TODO FILIP: fix
        // switch (codeList) {
        //     case DesignCodeList.LoadingDefinitionTypes:
        //         if (design.designStandardC2C?.id == DesignStandard.ACI || design.designStandardC2C?.id == DesignStandard.CSA) {
        //             textKey = textKey + '.HNA';
        //             if (textKey.includes('Title')) {
        //                 return this.localizationService.getString(textKey);
        //             }
        //             else {
        //                 const defaultUnit = textKey.includes('Stress') ? this.unitService.formatUnit(this.unitService.getDefaultUnit(UnitGroup.Stress)) : this.unitService.formatUnit(this.unitService.getDefaultUnit(UnitGroup.Force));
        //                 return formatKeyValue(this.localizationService.getString(textKey), { unit: defaultUnit });
        //             }
        //         }
        //         break;
        //     case DesignCodeList.OptimizationType:
        //         if (design.designStandardC2C?.id == DesignStandard.ACI || design.designStandardC2C?.id == DesignStandard.CSA) {
        //             textKey = textKey + '.HNA';
        //             return this.localizationService.getString(textKey);
        //         }
        //         break;
        // }

        return this.localizationService.getString(textKey);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private formatStringByUiPropertyId(textKey: string, design: Design, uiPropertyId: number) {
        // TODO FILIP: fix
        // switch (uiPropertyId) {
        //     case PropertyMetaDataC2C.Product_C2C_OptimizedLayout.id:
        //     case PropertyMetaDataC2C.Product_C2C_ConnectorEmbedmentDepthMode.id:
        //         if (design.isC2CHNA && design.isC2COverlay && design.loadCombinationsC2C && design.loadCombinationsC2C.length > 1) {
        //             return this.localizationService.getString('Agito.Hilti.C2C.Features.Design.Optimized.LoadCombination.Tooltip');
        //         }

        //         if (design.properties.get(PropertyMetaDataC2C.Product_C2C_ConnectorEmbedmentDepthMode.id).disabled) {
        //             return undefined;
        //         }

        //         return this.localizationService.getString(design.isC2CHNA ? textKey + '.HNA' : textKey);
        // }

        return this.localizationService.getString(textKey);
    }

    private createKBLink(kbLinkTemplate: string, kbNumber: string) {
        if (kbLinkTemplate == null) {
            return undefined;
        }

        if (kbNumber == null) {
            return kbLinkTemplate;
        }

        return formatKeyValue(kbLinkTemplate, { KBNUMBER: kbNumber });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private applyProperSingleApplicationTypeImage(items: IImageNameRadioGroupItem[], design: Design) {
        // TODO FILIP: fix
        // const applicationTypeSingleItem = items.find(item =>
        //     item.uiPropertyId == PropertyMetaDataC2C.General_C2C_ApplicationType.id &&
        //     item.value == ApplicationType.Single);

        // if (applicationTypeSingleItem != null) {
        //     applicationTypeSingleItem.image = `single${design.connectionType == ConnectionType.StructuralJoints ? '-intersections' : ''}`;
        // }
    }

    private getIconStyleForImage(image: string | undefined): IIconStyle | undefined {
        if (image == null || image == '') {
            return undefined;
        }

        return getSpriteAsIconStyle(image);
    }
}
