import { Component, ElementRef, Input, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import {
    DropdownItem, DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    CodeList, ICodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { Language } from '@profis-engineering/pe-ui-common/entities/code-lists/language';
import {
    ReportPaperSize
} from '@profis-engineering/pe-ui-common/entities/code-lists/report-paper-size';
import {
    IExportReportCompanyLayoutComponentInput
} from '@profis-engineering/pe-ui-common/entities/export-report-company-layout';
import {
    IExportReportProjectDetailsInput
} from '@profis-engineering/pe-ui-common/entities/export-report-project-details';
import {
    ReportTemplateEntity
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.ReportLayoutTemplate';
import {
    PaperSize, ReportType, TemplateOptionLocationType, TemplateOptionType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.ReportLayoutTemplate.Enums';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { LanguageCulture } from '@profis-engineering/pe-ui-common/services/localization.common';

import { environment } from '../../../environments/environment';
import reportImage from '../../images/report-image.png';
import { BrowserService } from '../../services/browser.service';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { CoreApiService } from '../../services/core-api.service';
import { CultureInfoService } from '../../services/culture-info.service';
import { DataService } from '../../services/data.service';
import {
    ApiDesignReportGenerateOptions, DesignService, PropertyIdValue, TemplateOptions
} from '../../services/design.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { NumberService } from '../../services/number.service';
import { ReportTemplateService } from '../../services/report-template.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { includeSprites } from '../../sprites';

const ReportTemplateId = {
    Default: null,
    Custom: 0
} as { Default: null; Custom: 0 };

export interface IExportReportComponentInput {
    propertyChange: (propertyChanges: PropertyIdValue[]) => Promise<void>;
}

interface ISectionCollapse {
    summary: boolean;
    projectDetails: boolean;
    layout: boolean;
}

// TODO TEAM: what is this?
const customTemplateId = Number.MAX_VALUE;

interface IExportReportProjectDetails extends IExportReportProjectDetailsInput {
    isIncludeDetailsInReport: boolean;
}

@Component({
    templateUrl: './export-report.component.html',
    styleUrls: ['./export-report.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})

export class ExportReportComponent implements OnInit {

    /* ====================================== VARIABLES ======================================*/

    @Input()
    public modalInstance!: ModalInstance<IExportReportComponentInput>;

    public isLoaded = false;
    public submitted = false;
    public isSectionCollapsed!: ISectionCollapse;

    public reportImage = reportImage;

    // Summary
    public numberOfPucks!: string;
    public maxSpacingPucks!: string;
    public totalFreeSpace!: string;
    public distancePucks!: string;
    public cartridges330!: string;
    public cartridges500!: string;

    // Project details
    public exportReportProjectDetailsInputs!: IExportReportProjectDetails;

    // Company layout
    public exportReportCompanyLayoutInputs!: IExportReportCompanyLayoutComponentInput;

    readonly DEFAULT_EN_LANGUAGE = 1033;

    /* ====================================== INITIALIZATION ====================================== */

    constructor(
        public localizationService: LocalizationService,
        private elementRef: ElementRef<HTMLElement>,
        private userService: UserService,
        private userSettingService: UserSettingsService,
        private dataService: DataService,
        private commonCodeListService: CommonCodeListService,
        private modalService: ModalService,
        private ngZone: NgZone,
        private numberService: NumberService,
        private reportTemplateService: ReportTemplateService,
        private coreApiService: CoreApiService,
        private browserService: BrowserService,
        private designService: DesignService,
        private unitService: UnitService,
        private cultureInfoService: CultureInfoService
    ) { }

    public ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-lines-expanded',
            'sprite-lines',
            'sprite-x'
        );

        this.isSectionCollapsed = {
            summary: false,
            layout: true,
            projectDetails: true
        };

        this.initSummarySection();
        this.initProjectDetailsSection();
        this.initLayoutSection();

        this.isLoaded = true;
    }

    /* ====================================== GETTERS ====================================== */

    public get designDetails() {
        return this.userService.design.designDetails;
    }

    public get trackingDetails() {
        return this.userService.design.trackingDetails;
    }

    public get designProperties() {
        return this.designDetails.properties;
    }

    public get codeLists() {
        return this.dataService.appData;
    }

    public get formValid() {
        const exportReportCompanyLayoutValid =
            this.exportReportCompanyLayoutInputs.templateDropdown.selectedValue != customTemplateId
            || this.exportReportCompanyLayoutInputs.reportEmailValid;

        return exportReportCompanyLayoutValid;
    }

    public get reportDisabled() {
        // TODO TEAM
        //return this.featuresVisibilityInfoService.isDisabled(Feature.Application_Report, this.design.region.id);
        return false;
    }

    public get exportHtmlReportEnabled() {
        return environment.exportHtmlReport;
    }

    private get reportTemplates() {
        return this.reportTemplateService.templates;
    }

    private get languages() {
        // With https://hilti.atlassian.net/browse/PSP-359 Chinese (Traditional), Chinese (Simplified), Japanese and Korean are disabled/hidden
        // TODO: This might come back later with https://hilti.atlassian.net/browse/PSP-560
        return (this.commonCodeListService.commonCodeLists[CommonCodeList.Language] as Language[]).filter(language => language.culture != LanguageCulture.pseudoLanguage
            && language.culture != 'zh-TW' && language.culture != 'zh-CN' && language.culture != 'ja' && language.culture != 'ko-KR');
    }

    private get defaultLanguage() {
        return (this.commonCodeListService.commonCodeLists[CommonCodeList.Language] as Language[]).find(language => language.culture === 'en-US')!;
    }

    public get reportPaperSizes() {
        return this.commonCodeListService.commonCodeLists[CommonCodeList.ReportPaperSize] as ReportPaperSize[];
    }

    public get reportTypes() {
        // TODO: Only the detailed report is desired right now.
        const allReportTypes = this.commonCodeListService.commonCodeLists[CommonCodeList.ReportType];
        return allReportTypes.filter(x => x.displayKey == 'Detailed');
    }

    public get formattedReportType() {
        // TODO when any other type instead of Detailed will be enabled changed it, till then hardcoded to detailed
        //const reportTypeId = this.exportReportCompanyLayoutInputs.reportTemplate?.ReportType;
        const reportType = this.reportTypes.find((item) => item.id === ReportType.Detailed);
        const codeListDeps = this.getCodeListDeps();

        return this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.Layout.ReportTypeFormat').replace('{reportType}', reportType?.getTranslatedNameText(codeListDeps) ?? '');
    }

    public get formattedPaperSize() {
        const paperSizeId = this.exportReportCompanyLayoutInputs.reportTemplate?.PaperSize;
        const paperSize = this.reportPaperSizes.find((item) => item.id === paperSizeId);
        const codeListDeps = this.getCodeListDeps();

        return this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.Layout.PaperSizeFormat').replace('{paperSize}', paperSize?.getTranslatedNameText(codeListDeps) ?? '');
    }

    public onReportTypeChange(reportTemplate: ReportTemplateEntity | undefined) {
        this.exportReportCompanyLayoutInputs.reportTemplate = reportTemplate;
    }

    /* ====================================== MAIN FUNCTIONS ====================================== */

    public async save() {
        if (this.submitted || !this.formValid) {
            return;
        }

        this.submitted = true;
        try {
            this.updateExportReportTrackingCounters();

            await this.updateProperties();
            await this.generateAndSaveReport();

            this.close();
        }
        finally {
            this.submitted = false;
        }
    }

    public close() {
        this.modalInstance.close();
    }

    public async generateAndSaveReport() {
        // DEV INFO: If you need any additional data (like static images), then add a field to report options and feed the data from here.
        const pdfBlob = await this.coreApiService.api.report.generate(await this.getGenerateOptions());
        const fileName = `${this.designDetails.isTemplate ? this.designDetails.templateName : this.designDetails.projectName + '_' + this.designDetails.designName}.pdf`;

        await this.browserService.downloadBlob(pdfBlob, fileName, false, false);
    }

    public async exportHtmlReport() {
        if (this.submitted || !this.formValid) {
            return;
        }

        this.submitted = true;
        try {
            // we must open a window before await
            const htmlWindow = window.open('about:blank', '_blank')!;

            await this.updateProperties();

            const input = await this.getGenerateOptions();
            const userLogoId = input.template?.userLogoId;

            let pdfHtml = await this.coreApiService.api.report.generateHtml(input);

            // set content urls for "UI html"
            pdfHtml = pdfHtml.replaceAll(/\/html-report\/static-content\/(.*?)(["'])/g, (substring, url, endTick) => `${environment.htmlReportContentUrl}static-content/${url}${endTick}`);
            pdfHtml = pdfHtml.replaceAll(/\/html-report\/custom-image\/(.*?)(["'])/g, (substring, url, endTick) => `${environment.htmlReportContentUrl}custom-image/${url}${endTick}`);
            pdfHtml = pdfHtml.replaceAll(/^(\s*)<!-- stylesheet: "(.+?)" -->(\s*)$/gm, (substring, prefix, url, postfix) => `${prefix}<link rel="stylesheet" href="${url}">${postfix}`);

            if (userLogoId != null) {
                pdfHtml = pdfHtml.replaceAll(`/html-report/template-logo/${userLogoId}`, '');
            }

            htmlWindow.document.write(pdfHtml);
            htmlWindow.document.close();

            // for report template logo we need to make a manual request with headers
            if (userLogoId != null) {
                const logo = await this.getReportTemplateLogo(userLogoId);
                if (logo != null) {
                    htmlWindow.document.querySelectorAll('.header-user-logo').forEach(element => {
                        if ('src' in element) {
                            element.src = logo;
                        }
                    });
                }
            }
        }
        finally {
            this.submitted = false;
        }
    }

    public async updateProperties() {
        const propertyChanges: PropertyIdValue[] = [];

        // Start page numbering/firstPageNumber has a max value of Number.MAX_SAFE_INTEGER which is more than C# int.MaxValue
        // we limit the number to 100 000 000
        if (this.exportReportCompanyLayoutInputs.firstPageNumber > 100000000) {
            this.exportReportCompanyLayoutInputs.firstPageNumber = 100000000;
        }

        propertyChanges.push({ propertyId: 'reportTemplateId', propertyValue: this.getReportTemplateId(), });
        propertyChanges.push({ propertyId: 'reportTypeId', propertyValue: this.exportReportCompanyLayoutInputs.reportTypeDropdown.selectedValue as number });
        propertyChanges.push({ propertyId: 'reportFirstPage', propertyValue: this.exportReportCompanyLayoutInputs.firstPageNumber });
        propertyChanges.push({ propertyId: 'reportLanguageId', propertyValue: this.exportReportCompanyLayoutInputs.languageDropdown.selectedValue as number });
        propertyChanges.push({ propertyId: 'reportPaperSizeId', propertyValue: this.exportReportCompanyLayoutInputs.paperSizeDropdown.selectedValue as number });
        propertyChanges.push({ propertyId: 'reportCompanyName', propertyValue: this.exportReportCompanyLayoutInputs.reportCompanyName });
        propertyChanges.push({ propertyId: 'reportAddress', propertyValue: this.exportReportCompanyLayoutInputs.reportAddress });
        propertyChanges.push({ propertyId: 'reportContactPerson', propertyValue: this.exportReportCompanyLayoutInputs.reportContactPerson });
        propertyChanges.push({ propertyId: 'reportPhoneNumber', propertyValue: this.exportReportCompanyLayoutInputs.reportPhoneNumber });
        propertyChanges.push({ propertyId: 'reportEmail', propertyValue: this.exportReportCompanyLayoutInputs.reportEmail });
        propertyChanges.push({ propertyId: 'reportNotes', propertyValue: this.exportReportProjectDetailsInputs.notes });

        await this.modalInstance.input!.propertyChange(propertyChanges);
    }

    private updateExportReportTrackingCounters() {
        const counters = this.trackingDetails.counters;

        counters.reportCreated++;

        const reportTemplateId = this.getReportTemplateId();
        if (reportTemplateId == ReportTemplateId.Custom) {
            counters.reportCreatedWithCustomTemplate++;
        }
        else if (reportTemplateId == ReportTemplateId.Default) {
            counters.reportCreatedWithDefaultTemplate++;
        }
        else {
            counters.reportCreatedWithUserTemplate++;
        }
    }

    private async getReportTemplateLogo(reportTemplateId: number): Promise<string | undefined> {
        const logo = await this.reportTemplateService.getLogo(reportTemplateId);
        if (logo != null) {
            if (logo.base64 != null) {
                return logo.base64;
            }
            else {
                return await new Promise<string>(resolve => {
                    const reader = new FileReader();
                    reader.addEventListener('load', () => {
                        resolve(reader.result as string);
                    }, false);
                    reader.readAsDataURL(logo.blob);
                });
            }
        }

        return undefined;
    }

    private async getGenerateOptions(): Promise<ApiDesignReportGenerateOptions> {
        if (this.designDetails.calculationResult?.kernelResult == null || this.designDetails.calculationResult?.kernelInput == null) {
            // We enable the report button only if we have a calculation result. This should never happen.
            throw new Error('No calculation result - we should have a calculation error SC');
        }

        const template: TemplateOptions = {
            excludeCompanyDetails: false
        };

        const isCustomReportTemplate = this.exportReportCompanyLayoutInputs.reportTemplate == null;
        const reportTemplate = isCustomReportTemplate
            ? this.reportTemplateService.templates.find(x => x.IsTemplate)
            : this.exportReportCompanyLayoutInputs.reportTemplate;

        // report templates are disabled if we have zero templates
        if (this.reportTemplateService.templates.length > 0) {
            if (reportTemplate == null) {
                throw new Error('report template not found');
            }

            // custom template will still take data from default template
            template.headerText = reportTemplate.TemplateOptions
                .find(x => x.TemplateOptionLocationType == TemplateOptionLocationType.Header && x.TemplateOptionType == TemplateOptionType.CustomText)
                ?.Value;

            template.footerText = reportTemplate.TemplateOptions
                .find(x => x.TemplateOptionLocationType == TemplateOptionLocationType.Footer && x.TemplateOptionType == TemplateOptionType.CustomText)
                ?.Value;

            template.userLogoId = reportTemplate.TemplateOptions
                .some(x => x.TemplateOptionLocationType == TemplateOptionLocationType.Header && x.TemplateOptionType == TemplateOptionType.Logo)
                ? reportTemplate.Id
                : undefined;

            template.excludeCompanyDetails = reportTemplate.RemoveProjectHeader ?? false;
            template.fax = reportTemplate.FaxNumber;
        }

        if (reportTemplate != null && !isCustomReportTemplate) {
            template.company = reportTemplate.Company;
            template.address = reportTemplate.Address;
            template.phone = reportTemplate.PhoneNumber;
            template.specifier = reportTemplate.ContactPerson;
            template.email = reportTemplate.Email;
        }
        else {
            template.company = this.exportReportCompanyLayoutInputs.reportCompanyName;
            template.address = this.exportReportCompanyLayoutInputs.reportAddress;
            template.phone = this.exportReportCompanyLayoutInputs.reportPhoneNumber;
            template.specifier = this.exportReportCompanyLayoutInputs.reportContactPerson;
            template.email = this.exportReportCompanyLayoutInputs.reportEmail;
        }

        const suiteVersion = (window as any).environment.applicationVersion;

        return {
            projectDesign: this.designDetails.projectDesign,
            reportPaperSizeId: this.designDetails.properties.reportPaperSizeId!,
            localization: {
                language: this.getReportLanguage(),
                numberDecimalSeparator: this.cultureInfoService.getNumberDecimalSeparator,
                numberGroupSeparator: this.cultureInfoService.getNumberGroupSeparator,
                globalRegionShortDatePattern: this.cultureInfoService.globalRegionShortDatePattern
            },
            template,
            version: suiteVersion,
            hiltiOnlineUrl: this.designDetails.commonRegion.hiltiOnlineUrl,
            // export report is disabled for design template so we can use ! on designName
            designName: this.designDetails.designName!,
            // only pass what is needed to report from CalculateResult
            calculateResult: {
                kernelResult: this.designDetails.calculationResult?.kernelResult,
                kernelInput: this.designDetails.calculationResult?.kernelInput
            }
        };
    }

    public getReportLanguage(): string {
        return this.languages.find((language) => language.id === this.designProperties.reportLanguageId)?.culture ?? this.defaultLanguage.culture!;
    }

    private initSummarySection() {
        this.numberOfPucks = this.getUnitString(this.designDetails.calculationResult?.kernelResult?.numberOfPucks, UnitGroup.None, this.localizationService.getString('Glass.ExportReport.Summary.NumberOfPucks.Unit')) ?? '';
        this.maxSpacingPucks = this.getUnitString(this.designDetails.calculationResult?.kernelResult?.maxSpacingPucks, UnitGroup.Length) ?? '';
        this.totalFreeSpace = this.getUnitString(this.designDetails.calculationResult?.kernelResult?.totalFreeSpace, UnitGroup.Length) ?? '';
        this.distancePucks = this.getUnitString(this.designDetails.calculationResult?.kernelResult?.distancePucks, UnitGroup.Length) ?? '';
        this.cartridges330 = this.getUnitString(this.designDetails.calculationResult?.kernelResult?.cartridges330, UnitGroup.None, this.localizationService.getString('Glass.ExportReport.Summary.Cartridges330.Unit')) ?? '';
        this.cartridges500 = this.getUnitString(this.designDetails.calculationResult?.kernelResult?.cartridges500, UnitGroup.None, this.localizationService.getString('Glass.ExportReport.Summary.Cartridges500.Unit')) ?? '';
    }

    private initProjectDetailsSection() {
        this.exportReportProjectDetailsInputs = {
            designName: this.designDetails.projectName + ', ' + this.designDetails.designName,
            fasteningPoint: undefined!,
            fasteningPointVisible: false,
            fasteningPointTitle: undefined!,
            fasteningPointId: undefined!,
            notes: this.designDetails.properties.reportNotes!,
            isIncludeDetailsInReport: false,
            reportDisabled: false,
            includeDetailsInReport: {
                items: [],
                selectedValues: new Set()
            }
        };
    }

    private initLayoutSection() {
        const codeListDeps = this.getCodeListDeps();
        const selectedReportTemplate = this.getReportTemplate();
        const selectedReportType = this.reportTypes.find((reportType) => reportType.id === this.designProperties.reportTypeId)?.id;
        const selectedReportLanguage = this.languages.find((language) => language.id === this.designProperties.reportLanguageId);
        const selectedReportPaperSize = this.reportPaperSizes.find((reportPaperSize) => reportPaperSize.id === this.designProperties.reportPaperSizeId);

        this.exportReportCompanyLayoutInputs = {
            reportTemplate: selectedReportTemplate,
            reportTemplateDisabled: false,
            isLoadCombinationDropdownVisible: false,
            displayLoadCaseDropdown: false,
            isExternalOnlineRussianUser: false,
            handrailSafetyDesign: false,
            firstPageNumber: this.designProperties.reportFirstPage ?? 1,
            reportCompanyName: this.designProperties.reportCompanyName,
            reportAddress: this.designProperties.reportAddress,
            reportContactPerson: this.designProperties.reportContactPerson,
            reportPhoneNumber: this.designProperties.reportPhoneNumber,
            reportEmail: this.designProperties.reportEmail,
            reportTypeDropdown: getLayoutDropdowns(this.reportTypes, 'export-report-layout-section-report-type-dropdown', codeListDeps, ReportType.Detailed, selectedReportType),
            languageDropdown: getLayoutDropdowns(this.languages, 'export-report-layout-section-language-dropdown', codeListDeps, this.DEFAULT_EN_LANGUAGE, selectedReportLanguage?.id),
            paperSizeDropdown: getLayoutDropdowns(this.reportPaperSizes, 'export-report-layout-section-papersize-dropdown', codeListDeps, PaperSize.A4, selectedReportPaperSize?.id),
            loadCombinationDropdown: {},
            loadCaseDropdown: {},
            loadCaseHandrailDropdown: {},
            templateDropdown: {
                id: 'export-report-layout-section-template-dropdown',
                items: [],
                selectedValue: selectedReportTemplate != undefined ? selectedReportTemplate.Id : customTemplateId,
            }
        } as IExportReportCompanyLayoutComponentInput;

        function getLayoutDropdowns(items: CodeList[], htmlClassId: string, codeListDeps: ICodeListTextDeps, defaultValue: number, selectedValue?: number): DropdownProps<number> {
            const actualValue = selectedValue ?? defaultValue;
            return {
                id: htmlClassId,
                items: items.map(item => {
                    return {
                        value: item.id,
                        text: item.getTranslatedNameText(codeListDeps) as string
                    } as DropdownItem<number>;
                }).sort((a, b) => String(a.text[0]).localeCompare(b.text[0])),
                selectedValue: actualValue
            };
        }

    }

    getReportTemplate() {
        const defaultTemplate = this.reportTemplateService.templates.find((template) => template.IsTemplate) ?? undefined;

        if (this.designProperties.reportTemplateId == ReportTemplateId.Default) {
            return defaultTemplate;
        }

        if (this.designProperties.reportTemplateId == ReportTemplateId.Custom) {
            return undefined;
        }

        return this.reportTemplates.find((reportTemplate) => reportTemplate.Id === this.designProperties.reportTemplateId) ?? defaultTemplate;
    }

    getReportTemplateId() {
        const reportTemplate = this.exportReportCompanyLayoutInputs.reportTemplate;
        if (reportTemplate == null) {
            return ReportTemplateId.Custom;
        }

        if (reportTemplate.IsTemplate) {
            return ReportTemplateId.Default;
        }

        return reportTemplate.Id;
    }

    /* ====================================== SUPPORT FUNCTIONS ====================================== */

    public getArrowClass(isCollapsed: boolean) {
        return isCollapsed ? 'pe-ui-glass-sprite-lines-expanded' : 'pe-ui-glass-sprite-lines';
    }

    private getCodeListDeps(): ICodeListTextDeps {
        return {
            localizationService: this.localizationService,
            numberService: this.numberService
        } as ICodeListTextDeps;
    }

    private getUnitString(value: number | undefined, unitGroup: UnitGroup, unitString?: string): string | undefined {
        if (value == undefined) {
            return undefined;
        }

        const defaultUnit = this.unitService.getDefaultUnit(unitGroup);
        const internalUnit = this.unitService.getInternalUnit(unitGroup);
        const defaultPrecision = this.unitService.getPrecision(defaultUnit);

        const valueToFormat = this.unitService.convertUnitValueArgsToUnit(value, internalUnit, defaultUnit, true);

        const number = this.unitService.formatNumber(valueToFormat, defaultPrecision);
        const unit = unitString ?? this.unitService.formatUnit(this.unitService.getDefaultUnit(unitGroup));

        return number + ' ' + unit;
    }
}
